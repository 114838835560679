<template>
  <form class="mt-1 filters" @submit.prevent="onSubmit">
    <div v-if="firstRow" class="row">
      <!-- <div class="row"> -->
      <div
        v-if="search"
        class="col-sm-12 mb-h"
        :class="{
          'col-lg': firstRow < 4,
          'col-lg-6': firstRow === 4,
          '':
            smallHeightForCustomMultiselectAndSelectComponents === true
        }"
      >
<!--        <label>&nbsp;</label>-->
        <div class="input-group input-group-sm filters__input filters__input--clients">
          <span class="input-group-addon">
            <i class="fa fa-search"></i>
          </span>
          <input
            id="search"
            name="search"
            :placeholder="$t('general.search')"
            class="form-control"
            :value="value.search"
            ref="search"
            @input="selectInFiltersWithDelay"
          />
          <span v-show="value.search !== ''" class="clear-input text-muted">
            <i class="fa fa-remove" @click="searchClear"></i>
          </span>
        </div>
      </div>

      <div v-if="location"
           class="col-xs-12 mb-h input-group-for-location-select-in-TablePaginationV3--filters portal-pagination-filters-custom-margins-for-first-filters-row col-sm-12 mb-h"
           :class="{
          'col-lg': firstRow < 4,
          'col-lg-6': firstRow === 4,
          '':
            smallHeightForCustomMultiselectAndSelectComponents === true
        }">

              <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': value.location.data}"></i>
                </span>
        <SelectComponentV3ForUseInLocationSelectors

          v-if="showLocationFilter"
          no-wrap-selected-option
          class="select-location-in-TablePaginationV3--filters"
          enable-max-option-width
          small-size
          :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
          :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
          v-model="value.location.data"
          track-by="name"
          option-id-name="id"
          :no-options-found="$t('general.noResultForSearch')"
          :async-function="requestLocations.bind(this)"
          :placeholder="$t('portalStats.selectLocation')"
          @select="selectLocationInFilters"
        >
          <template #dropdown-start>
            <div class="ml-h mt-1">
              <Switch-component
                v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                :disabled="isDisabledWithChilds"
                @input="toggleWithChilds"
                :label="$t('general.withChild')"
                class=""
                v-model="value.location.with_childs"
              />
            </div>
          </template>
        </SelectComponentV3ForUseInLocationSelectors>
      </div>

      <div
        v-if="sort"
        class="mb-h"
        :class="{
          'col-lg col-sm': firstRow < 4,
          'col-lg-2 col-sm-4': firstRow === 4,
          'portal-pagination-filters-custom-margins-for-first-filters-row':
            smallHeightForCustomMultiselectAndSelectComponents === true
        }"
      >
        <div class="input-group flex-column">
<!--          <label for="filter" class="mb-0">-->
<!--            <small>{{ $t('general.filtersSort') }}</small>-->
<!--          </label>-->
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i
                class="text-primary fa"
                :class="[value.sort_order === 1 ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc']"
                @click="selectInFilters({ sort_order: true })"
              />
            </span>
            <select
              id="filter"
              class="form-control form-control-sm"
              ref="sort_by"
              :value="value.sort_by"
              @change="selectInFilters"
            >
              <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        v-if="limit"
        class="mb-h"
        :class="{
          'col-lg col-sm': firstRow < 4,
          'col-lg-2 col-sm-4': firstRow === 4,
          'portal-pagination-filters-custom-margins-for-first-filters-row':
            smallHeightForCustomMultiselectAndSelectComponents === true
        }"
      >
        <div class="input-group flex-column">
<!--          <label for="page-limit" class="mb-0">-->
<!--            <small>{{ $t('general.filtersPageLimit') }}</small>-->
<!--          </label>-->
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-list" />
            </span>
            <input
              id="page-limit"
              name="page-limit"
              type="number"
              class="form-control"
              ref="limit"
              :value="value.limit"
              @input="selectInFiltersWithDelay"
            />
          </div>
        </div>
      </div>
      <div
        v-if="offset"
        class="mb-h"
        :class="{
          'col-lg col-sm': firstRow < 4,
          'col-lg-2 col-sm-4': firstRow === 4,
          'portal-pagination-filters-custom-margins-for-first-filters-row':
            smallHeightForCustomMultiselectAndSelectComponents === true
        }"
      >
        <div class="input-group flex-column">
          <label for="page-offset" class="mb-0">
            <small>{{ $t('general.filtersPageOffset') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-list-ol" />
            </span>
            <input
              id="page-offset"
              name="page-offset"
              type="number"
              class="form-control"
              ref="offset"
              :value="value.offset"
              @input="updateData"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="secondRow" class="row">
<!--      <div v-if="profile" class="col-xs-12 mb-h" :class="{ 'col-lg': secondRow < 2, 'col-lg-6': secondRow === 2 }">-->
<!--        <br />-->
<!--        <Multi-select-component-->
<!--          :class="{-->
<!--            'multiselect-in-portal-pagination-for-accounts-filters': smallHeightForCustomMultiselectAndSelectComponents === true-->
<!--          }"-->
<!--          option-id-name="id"-->
<!--          track-by="name"-->
<!--          :placeholder="$t('portalStats.selectProfile')"-->
<!--          :async-function="requestProfiles.bind(this)"-->
<!--          v-model="value.profiles"-->
<!--        />-->
<!--      </div>-->
      <div v-if="profile" class="col-xs-12 mb-h col-lg-3" style="z-index: 9999">
        <br>
                  <div class="input-group input-group-sm w-100">
            <span class="input-group-addon">
              <i class="fa fa-file-o" />
            </span>

        <Multi-select-component
          :class="{
            'multiselect-in-portal-pagination-for-accounts-filters': smallHeightForCustomMultiselectAndSelectComponents === true,
            'w-100': true
          }"
          option-id-name="id"
          track-by="name"
          :placeholder="$t('portalStats.selectProfile')"
          :async-function="requestProfiles.bind(this)"
          v-model="value.profiles"
          @select="selectInFilters"
        />
                  </div>
      </div>
            <div v-if="social" class="mb-h" :class="{ 'col-lg col-sm': fourthRow < 4, 'col-lg-2 col-sm-6': fourthRow === 4 }">
        <div class="input-group flex-column">
          <label for="filter-social" class="mb-0 text-nowrap">
            <small>{{ $t('general.filtersSocialNetwork') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-user" />
            </span>
            <select
              id="filter-social"
              class="form-control form-control-sm limit-select-height"
              v-model="q.social_network"
              @change="selectInFilters"
            >
              <option
                v-for="social of SOCIALS"
                :key="social"
                :value="social === 'unset' ? '' : social"
                :selected="social === 'unset'"
              >
                {{ $t(`portalStats.auth.${social}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="visits" class="mb-h" :class="{ 'col-lg col-sm': fourthRow < 4, 'col-lg-2 col-sm-3': fourthRow === 4 }">
        <div class="input-group flex-column">
          <label for="visits-all" class="mb-0 text-nowrap">
            <small>{{ $t('general.filtersVisitsAll') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon" role="button" @click="handleChangeVisitsGreaterLessOperator">
              <i class="fa" v-tooltip.auto="{ content: $t(`misc.${visitsGreaterLessOperator}`) }">
                {{ visitsGreaterLessOperator === '$gt' ? '>' : '' }}
                {{ visitsGreaterLessOperator === '$lt' ? '<' : '' }}
                {{ visitsGreaterLessOperator === '$eq' ? '=' : '' }}
              </i>
            </span>

            <input
              @input="selectInFiltersWithDelay"
              id="visits-all"
              name="visits-all"
              type="text"
              class="form-control"
              placeholder="4"
              v-mask="'#########'"
              v-model.number="q['visits.all'][visitsGreaterLessOperator]"
            />
            <!-- <span class="clear-input text-muted">
                  <i class="fa fa-remove" @click="filters.q['visits.all'] = {}"></i>
                </span> -->
          </div>
        </div>
      </div>
      <div v-if="subscription" class="mb-h" :class="{ 'col-lg col-sm': fourthRow < 4, 'col-lg-2 col-sm-6': fourthRow === 4 }">
        <div class="input-group flex-column">
          <label for="filter-subscription" class="mb-0 text-nowrap">
            <small>{{ $t('general.filtersSubscription') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-user" />
            </span>
            <select
              @change="selectInFilters"
              id="filter-subscription"
              class="form-control form-control-sm limit-select-height"
              v-model="q.subscribe_id"
            >
              <option
                :key="'subscribed-unset'"
                :value="'unset'"
              >
                {{ $t(`userAccount.unsetForFilter`) }}
              </option>
              <option
                :key="'subscribed-subscribed'"
                :value="{$exists: true, $ne: ''}"
              >
                {{ $t(`userAccount.subscribed`) }}
              </option>
              <option
                :key="'subscribed-notsubscribed'"
                :value="{$in: ['', null]}"
              >
                {{ $t(`userAccount.notSubscribed`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
<!--      <div v-if="location" class="col-xs-12 mb-h input-group-for-location-select-in-TablePaginationV3&#45;&#45;filters mb-h" :class="{ 'col-lg': secondRow < 2, 'col-lg-6': secondRow === 2, 'col-lg-4': smallWidthLocationSelector }">-->
<!--&lt;!&ndash;        <SelectComponentV2&ndash;&gt;-->
<!--&lt;!&ndash;          @select="selectLocation"&ndash;&gt;-->
<!--&lt;!&ndash;          :class="{&ndash;&gt;-->
<!--&lt;!&ndash;            'select-in-portal-pagination-filters': smallHeightForCustomMultiselectAndSelectComponents === true&ndash;&gt;-->
<!--&lt;!&ndash;          }"&ndash;&gt;-->
<!--&lt;!&ndash;          v-model="value.location.data"&ndash;&gt;-->
<!--&lt;!&ndash;          track-by="name"&ndash;&gt;-->
<!--&lt;!&ndash;          option-id-name="id"&ndash;&gt;-->
<!--&lt;!&ndash;          :async-function="requestLocations.bind(this)"&ndash;&gt;-->
<!--&lt;!&ndash;          :placeholder="$t('portalStats.selectLocation')"&ndash;&gt;-->
<!--&lt;!&ndash;        >&ndash;&gt;-->
<!--&lt;!&ndash;          <template #dropdown-start>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="select__option select__option_clickable" @click="toggleWithChilds">&ndash;&gt;-->
<!--&lt;!&ndash;              <Checkbox-component&ndash;&gt;-->
<!--&lt;!&ndash;                class="w-100"&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="isDisabledWithChilds"&ndash;&gt;-->
<!--&lt;!&ndash;                name="with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                :label="$t('portalStats.selectLocationWithChild')"&ndash;&gt;-->
<!--&lt;!&ndash;                id="with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                :checked="value.location.with_childs === true"&ndash;&gt;-->
<!--&lt;!&ndash;                @click="toggleWithChilds"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--&lt;!&ndash;          </template>&ndash;&gt;-->
<!--&lt;!&ndash;        </SelectComponentV2>&ndash;&gt;-->
<!--                                                                                 <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': value.location.data}"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-TablePaginationV3&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="value.location.data"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                           :disabled="isDisabledWithChilds"-->
<!--                          @input="toggleWithChilds"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          class=""-->
<!--                           v-model="value.location.with_childs"-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->
<!--      </div>-->
    </div>
    <div v-if="thirdRow" class="row">
      <div v-if="city" class="mb-h" :class="{ 'col-lg col-sm': thirdRow < 4, 'col-lg-3 col-sm-6': thirdRow === 4 }">
        <div class="input-group flex-column">
          <label for="filter-city" class="mb-0 text-nowrap">
            <small>{{ $t('general.filtersCity') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-home" />
            </span>
            <input
              @input="selectInFiltersWithDelay"
              id="filter-city"
              name="filter-city"
              type="text"
              class="form-control"
              ref="filter-city"
              v-model="q.city"
              :placeholder="$t('general.filtersTownPlaceholder')"
            />
          </div>
        </div>
      </div>
      <div v-if="homeTown" class="mb-h" :class="{ 'col-lg col-sm': thirdRow < 4, 'col-lg-3 col-sm-6': thirdRow === 4 }">
        <div class="input-group flex-column">
          <label for="filter-home-town" class="mb-0 text-nowrap">
            <small>{{ $t('general.filtersHomeTown') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-home" />
            </span>
            <input
              @input="selectInFiltersWithDelay"
              id="filter-home-town"
              name="filter-home-town"
              type="text"
              class="form-control"
              ref="filterHomeTown"
              :placeholder="$t('general.filtersTownPlaceholder')"
              v-model="q.home_town"
            />
          </div>
        </div>
      </div>
      <div v-if="birthday" class="mb-h" :class="{ 'col-lg col-sm': thirdRow < 4, 'col-lg-3 col-sm-6': thirdRow === 4 }">
        <div class="input-group flex-column">
          <label for="year-of-birth" class="mb-0 text-nowrap">
            <small>{{ $t('general.filtersYearOfBirth') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-birthday-cake" />
            </span>
            <input
              @input="selectInFiltersWithDelay"
              id="year-of-birth"
              name="year-of-birth"
              type="text"
              class="form-control"
              placeholder="1994"
              v-model.number="q['date_of_birth_struct.year']"
              v-mask="'####'"
            />
          </div>
        </div>
      </div>
      <div v-if="sex" class="mb-h" :class="{ 'col-lg  col-sm': thirdRow < 4, 'col-lg-3 col-sm-6': thirdRow === 4 }">
        <div class="input-group flex-column">
          <label for="filter-gender" class="mb-0 text-nowrap">
            <small>{{ $t('general.filtersGender') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-venus-mars" />
            </span>
            <select id="filter-gender" @change="selectInFilters" class="form-control form-control-sm limit-select-height" v-model="q.sex">
              <option
                v-for="gender of GENDERS"
                :key="gender"
                :value="gender === 'unset' ? '' : gender"
                :selected="gender === 'unset'"
              >
                {{ $t(`userAccount.gender.${gender}`) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div v-if="fourthRow" class="row flex-row-reverse">
<!--      <div v-if="true" class="mb-h" :class="{ 'col-lg-3 col-sm': fourthRow < 4, 'col-lg-2 col-sm-3': fourthRow === 4 }">-->
<!--        <br />-->
<!--&lt;!&ndash;        <button class="btn btn-primary btn-sm btn-block" type="submit">&ndash;&gt;-->
<!--&lt;!&ndash;          {{ $t('general.applyFilters') }}&ndash;&gt;-->
<!--&lt;!&ndash;        </button>&ndash;&gt;-->
<!--      </div>-->
      <slot name="before-submit" />
<!--      <div v-if="social" class="mb-h" :class="{ 'col-lg col-sm': fourthRow < 4, 'col-lg-2 col-sm-6': fourthRow === 4 }">-->
<!--        <div class="input-group flex-column">-->
<!--          <label for="filter-social" class="mb-0 text-nowrap">-->
<!--            <small>{{ $t('general.filtersSocialNetwork') }}</small>-->
<!--          </label>-->
<!--          <div class="input-group input-group-sm">-->
<!--            <span class="input-group-addon">-->
<!--              <i class="fa fa-user" />-->
<!--            </span>-->
<!--            <select-->
<!--              id="filter-social"-->
<!--              class="form-control form-control-sm limit-select-height"-->
<!--              v-model="q.social_network"-->
<!--            >-->
<!--              <option-->
<!--                v-for="social of SOCIALS"-->
<!--                :key="social"-->
<!--                :value="social === 'unset' ? '' : social"-->
<!--                :selected="social === 'unset'"-->
<!--              >-->
<!--                {{ $t(`portalStats.auth.${social}`) }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div v-if="visits" class="mb-h" :class="{ 'col-lg col-sm': fourthRow < 4, 'col-lg-2 col-sm-3': fourthRow === 4 }">-->
<!--        <div class="input-group flex-column">-->
<!--          <label for="visits-all" class="mb-0 text-nowrap">-->
<!--            <small>{{ $t('general.filtersVisitsAll') }}</small>-->
<!--          </label>-->
<!--          <div class="input-group input-group-sm">-->
<!--            <span class="input-group-addon" role="button" @click="handleChangeVisitsGreaterLessOperator">-->
<!--              <i class="fa" v-tooltip.auto="{ content: $t(`misc.${visitsGreaterLessOperator}`) }">-->
<!--                {{ visitsGreaterLessOperator === '$gt' ? '>' : '' }}-->
<!--                {{ visitsGreaterLessOperator === '$lt' ? '<' : '' }}-->
<!--                {{ visitsGreaterLessOperator === '$eq' ? '=' : '' }}-->
<!--              </i>-->
<!--            </span>-->

<!--            <input-->
<!--              id="visits-all"-->
<!--              name="visits-all"-->
<!--              type="text"-->
<!--              class="form-control"-->
<!--              placeholder="4"-->
<!--              v-mask="'#########'"-->
<!--              v-model.number="q['visits.all'][visitsGreaterLessOperator]"-->
<!--            />-->
<!--            &lt;!&ndash; <span class="clear-input text-muted">-->
<!--                  <i class="fa fa-remove" @click="filters.q['visits.all'] = {}"></i>-->
<!--                </span> &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div v-if="subscription" class="mb-h" :class="{ 'col-lg col-sm': fourthRow < 4, 'col-lg-2 col-sm-6': fourthRow === 4 }">-->
<!--        <div class="input-group flex-column">-->
<!--          <label for="filter-subscription" class="mb-0 text-nowrap">-->
<!--            <small>{{ $t('general.filtersSubscription') }}</small>-->
<!--          </label>-->
<!--          <div class="input-group input-group-sm">-->
<!--            <span class="input-group-addon">-->
<!--              <i class="fa fa-user" />-->
<!--            </span>-->
<!--            <select-->
<!--              id="filter-subscription"-->
<!--              class="form-control form-control-sm limit-select-height"-->
<!--              v-model="q.subscribe_id"-->
<!--            >-->
<!--              <option-->
<!--                :key="'subscribed-unset'"-->
<!--                :value="'unset'"-->
<!--              >-->
<!--                {{ $t(`userAccount.unsetForFilter`) }}-->
<!--              </option>-->
<!--              <option-->
<!--                :key="'subscribed-subscribed'"-->
<!--                :value="{$exists: true, $ne: ''}"-->
<!--              >-->
<!--                {{ $t(`userAccount.subscribed`) }}-->
<!--              </option>-->
<!--              <option-->
<!--                :key="'subscribed-notsubscribed'"-->
<!--                :value="{$in: ['', null]}"-->
<!--              >-->
<!--                {{ $t(`userAccount.notSubscribed`) }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div v-if="date" class="mb-h" :class="{ 'col-lg col-sm': fourthRow < 4, 'col-lg-6 col-sm-3': fourthRow === 4 }">
        <div class="input-group input-group-sm flex-column">
          <label for="datepicker-label" class="mb-0">
            <small>{{ $t('general.feltersDateRange') }}</small>
          </label>
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i class="fa fa-calendar" />
            </span>
            <date-picker
              class="w-100 text-muted"
              v-model="value.date"
              type="date"
              input-class="mx-input datepicker_height_27 text-left input-group-sm"
              :show-icon="false"
            ></date-picker>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mask } from 'vue-the-mask';

import DatePicker from './date-picker.vue';
import MultiSelectComponent from './Universal/select/multi-select-component.vue';
import SelectComponent from './Universal/select/select-component.vue';
import CheckboxComponent from './Universal/Checkbox-component.vue';
import SelectComponentV2 from './Universal/select/select-componentV2.vue';
import SwitchComponent from './Universal/Switch-component.vue';

import helpers from '../helpers';
import portalProfilesService from '../services/portalProfilesService';
import locationService from '../services/locationService';
import SelectComponentV3ForUseInLocationSelectors from './Universal/select/select-componentV3ForUseInLocationSelectors.vue';


const GENDERS = ['male', 'female', 'unset'];
const SOCIALS = ['vk', 'facebook', 'instagram', 'unset'];

export default {
  name: 'TablePaginationV3ForAccounts',
  components: {
    MultiSelectComponent,
    SelectComponent,
    CheckboxComponent,
    DatePicker,
    SelectComponentV2,
    SwitchComponent,
    SelectComponentV3ForUseInLocationSelectors
  },

  props: {
    value: {
      search: { type: String, required: false },
      limit: { type: String, required: false },
      offset: { type: String, required: false },
      sort_by: { type: String, required: false },
      sort_order: { type: String, required: false },
      profiles: { type: Array, required: false },
      location: {
        data: { type: Object, required: false },
        with_childs: { type: Boolean, required: false }
      },
      q: { type: Object, required: false }
    },
    onSubmit: { type: Function, required: true },
    options: { type: Array, required: true },
    showFilters: { type: Array, required: true },
    showSlotForProfileSelctor: {
      type: Boolean,
      required: false,
      default: false
    },
    smallHeightForCustomMultiselectAndSelectComponents: {
      type: Boolean,
      default: false
    },
    smallWidthLocationSelector: {
      type: Boolean,
      default: false
    },
    // showSearchField: { type: Boolean, default: true },
    // Плохо, но по-другому не получается :(
    q: { type: [Object, Boolean], default: false }
  },
  directives: { mask },
  watch: {
    filters: {
      handler() {
        this.updateData(this);
      },
      deep: true
    }
  },
  data() {
    return {
      delayTimer: null,
      showLocationFilter: true,
      locationInFiltersData: [],
      visitsGreaterLessOperator: '$gt'
    };
  },
  // created(){
  //   if (typeof this.value.location === 'object' && Object.keys(this.value.location).length > 0) {
  //       this.locationInFiltersData = [];
  //     } else {
  //       this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
  //     }
  // },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    firstRow() {
      return [this.search, this.sort, this.limit, this.offset].filter(Boolean).length;
    },
    secondRow() {
      return [this.profile, this.location].filter(Boolean).length;
    },
    thirdRow() {
      return [this.city, this.homeTown, this.birthday, this.sex].filter(Boolean).length;
    },
    fourthRow() {
      return [this.social, this.visits, true].filter(Boolean).length;
    },
    profile() {
      return this.showFilters.some((filter) => filter === 'profile');
    },
    search() {
      return this.showFilters.some((filter) => filter === 'search');
    },
    sex() {
      return this.showFilters.some((filter) => filter === 'sex');
    },
    location() {
      return this.showFilters.some((filter) => filter === 'location');
    },
    birthday() {
      return this.showFilters.some((filter) => filter === 'birthday');
    },
    social() {
      return this.showFilters.some((filter) => filter === 'social');
    },
    subscription() {
      return this.showFilters.some((filter) => filter === 'subscription');
    },
    date() {
      return this.showFilters.some((filter) => filter === 'date');
    },
    visits() {
      return this.showFilters.some((filter) => filter === 'visits');
    },
    sort() {
      return this.showFilters.some((filter) => filter === 'sort');
    },
    limit() {
      return this.showFilters.some((filter) => filter === 'limit');
    },
    city() {
      return this.showFilters.some((filter) => filter === 'city');
    },
    homeTown() {
      return this.showFilters.some((filter) => filter === 'home_town');
    },
    offset() {
      return this.showFilters.some((filter) => filter === 'offset');
    },
    isDisabledWithChilds() {
      if (this.value.location.data.id === '') {
        return true;
      }
      return false;
    },
    GENDERS() {
      return GENDERS;
    },
    SOCIALS() {
      return SOCIALS;
    }
  },
  methods: {
    selectLocation(location) {
      if (!location) {
        this.value.location.data = {};
      }
    },
    async requestProfiles(searchQuery) {
      // return portalProfilesService.requestProfiles(searchQuery);
      return portalProfilesService.requestProfilesFilteredByLocationAndWithChilds(this, searchQuery);
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    handleChangeVisitsGreaterLessOperator() {
      if (this.visitsGreaterLessOperator === '$gt') {
        this.visitsGreaterLessOperator = '$lt';
        delete this.q['visits.all'].$gt;
        delete this.q['visits.all'].$eq;
      } else if (this.visitsGreaterLessOperator === '$lt') {
        this.visitsGreaterLessOperator = '$eq';
        delete this.q['visits.all'].$lt;
        delete this.q['visits.all'].$gt;
      } else {
        this.visitsGreaterLessOperator = '$gt';
        delete this.q['visits.all'].$lt;
        delete this.q['visits.all'].$eq;
      }
    },
    toggleWithChilds() {
      // this.value.location.with_childs = !this.value.location.with_childs;
      this.$nextTick(() => {
        this.onSubmit();
      });
    },
    searchClear() {
      this.$refs.search.value = '';
      // this.updateData(this);
      this.selectInFilters();
    },

    selectLocationInFilters(location) {
      this.selectInFilters();
    },

    selectInFilters(props) {
      this.updateData(props);
      this.onSubmit();
    },

    selectInFiltersWithDelay(props) {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }
      this.delayTimer = setTimeout(() => {
        this.updateData(props);
        this.onSubmit();
      }, 1000)
    },

    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },

    flipSort(current) {
      return current === 1 ? -1 : 1;
    },
    updateData(props) {
      // console.log(props);
      const value = JSON.parse(JSON.stringify(this.value));
      const q = JSON.parse(JSON.stringify(this.q));

      this.$emit('input', {
        ...value,
        limit: helpers.validatePagination(this.$refs.limit.value, 1),
        offset: this.offset ? helpers.validatePagination(this.$refs.offset.value) : 0,
        sort_by: this.$refs.sort_by.value,
        sort_order: props?.sort_order ? this.flipSort(this.value.sort_order) : this.value.sort_order,
        search: this.$refs.search.value,
        // profiles: this.value.profiles,
        // location: this.value.location,
        // date: this.value.date,
        q: this.q ? q : {}
      });
    }
  }
};
</script>

<style lang="scss" scoped>


.limit-select-height {
  height: 27px !important;
}

.limit-pagination-width {
  max-width: 200px;
}

.page-offset-no-display {
  display: none;
}
</style>
<style lang="scss">
  .input-group-for-location-select-in-TablePaginationV3--filters {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;
  }

  .select-location-in-TablePaginationV3--filters .select__list-container {
    width: 400px;
  }

.datepicker_height_27 {
  height: 27px !important;
}

.multiselect-in-portal-pagination-for-accounts-filters {
  min-height: 27px !important;
}

.multiselect-in-portal-pagination-for-accounts-filters .multi-select__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 27px !important;
  align-items: center;
}

.multiselect-in-portal-pagination-for-accounts-filters .multi-select__input {
  min-height: 27px !important;
  margin-bottom: 0px !important;
}

.multiselect-in-portal-pagination-for-accounts-filters .arrow {
  min-height: 27px !important;
}

.multiselect-in-portal-pagination-for-accounts-filters .multi-select__tag {
  margin-bottom: 0px;
  height: 22px !important;
}

.select-in-portal-pagination-filters {
  min-height: 27px !important;
}

.select-in-portal-pagination-filters .select__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 27px !important;
  align-items: center;
}

.select-in-portal-pagination-filters .select__input {
  min-height: 27px !important;
  margin-bottom: 0px !important;
}

.select-in-portal-pagination-filters .arrow {
  min-height: 27px !important;
}

.select-in-portal-pagination-filters .select__tag {
  margin-bottom: 0px;
  height: 22px !important;
}

.portal-pagination-filters-custom-margins-for-first-filters-row {
  margin-bottom: -2px !important;
}
</style>
